define("apps-core-ui/utils/now-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var nowProxy = {
    now: function now() {
      return new Date();
    },
    nowTimeDefault: function nowTimeDefault(hours, minutes, seconds) {
      return new Date(this.now().setHours(hours, minutes, seconds, 0));
    }
  };
  var _default = nowProxy;
  _exports.default = _default;
});