define("apps-core-ui/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ed0AB3SX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"error-page\"],[14,0,\"error-page\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"Oops, something went wrong. \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"message\"],[12],[2,\"Please try again. If this error persists, please contact your mutual administrator.\"],[13],[2,\"\\n  \"],[10,\"svg\"],[14,1,\"error-svg\"],[14,\"width\",\"15vw\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"version\",\"1.1\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"viewBox\",\"0 0 100 100\"],[14,\"enable-background\",\"new 0 0 100 100\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[2,\"\\n    \"],[10,\"path\"],[14,0,\"grey-fill\"],[14,\"stroke\",\"#231815\"],[14,\"stroke-width\",\"3.6\"],[14,\"stroke-miterlimit\",\"10\"],[14,\"d\",\"M61.758,4.716\"],[12],[13],[2,\"\\n    \"],[10,\"path\"],[14,0,\"grey-fill\"],[14,\"d\",\"M38.687,53.946l8.769,8.77l2.545,2.546l2.546-2.547l8.767-8.768l8.769,8.769l2.546,2.546l2.546-2.547  l4.977-4.977V91.4H19.85c0-7.191,0-23.513,0-33.663l4.978,4.979l2.546,2.546l2.546-2.546C29.919,62.716,35.352,57.281,38.687,53.946   M61.313,48.855L50.001,60.17L38.687,48.855c0,0-11.314,11.314-11.314,11.314L16.25,49.046c0,0,0,45.954,0,45.954h67.5  c0,0,0-45.955,0-45.954L72.628,60.17L61.313,48.855L61.313,48.855z\"],[12],[13],[2,\"\\n    \"],[10,\"path\"],[14,0,\"grey-fill\"],[14,\"d\",\"M63.2,5.026V5h-0.026h-3.573H16.25v33.889l11.124,11.124l11.314-11.313l11.314,11.313l11.312-11.313  l11.314,11.313L83.75,38.889V25.499L63.2,5.026z M63.2,10.108l15.426,15.367H63.2V10.108z M72.628,44.921l-8.769-8.768l-2.546-2.545  l-2.546,2.546l-8.767,8.768l-8.769-8.768l-2.545-2.546l-2.545,2.546l-8.769,8.768l-7.523-7.523V8.6h39.751v20.475h20.55v8.323  L72.628,44.921z\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "apps-core-ui/templates/error.hbs"
  });

  _exports.default = _default;
});