define("apps-core-ui/components/core-header", ["exports", "apps-core-ui/templates/components/core-header", "apps-core-ui/utils/window-proxy"], function (_exports, _coreHeader, _windowProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _coreHeader.default,
    tagName: '',
    eventbus: Ember.inject.service(),
    router: Ember.inject.service(),
    userPermissions: Ember.inject.service(),
    canSpoofUser: Ember.computed(function () {
      return this.userPermissions.getPermissions('auth').spoofUser;
    }),
    actions: {
      toggle: function toggle(event) {
        this.eventbus.trigger(event);
      },
      linkTo: function linkTo(url, _linkTo, target) {
        if (_linkTo) {
          return this.router.transitionTo(_linkTo);
        }

        if (target) {
          return _windowProxy.default.open(url);
        }

        if (url === this.appsCoreHyperlinks.signOutUrl) {
          _windowProxy.default.removeSession();
        }

        return _windowProxy.default.changeLocation(url);
      },
      showSpoofBar: function showSpoofBar() {
        this.set('spoofBarSelected', true);
      }
    }
  });

  _exports.default = _default;
});