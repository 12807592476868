define("apps-core-ui/routes/application", ["exports", "apps-core-ui/utils/window-proxy", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _windowProxy, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    eventbus: Ember.inject.service(),
    router: Ember.inject.service(),
    handleJsonApiError: function handleJsonApiError(error) {
      var transitions = {
        '400': 'bad-request',
        '404': 'not-found',
        '500': 'error'
      };
      var status = error.errors[0].status;

      if (transitions[status]) {
        return this.transitionTo(transitions[status]);
      }

      if (Ember.A(['403', '401']).includes(status)) {
        _windowProxy.default.changeLocation("".concat(this.appsCoreHyperlinks.signInUrl, "?next=").concat(_windowProxy.default.mungeLocationHash()));

        return undefined;
      }
    },
    actions: {
      error: function error(_error) {
        if (_error && _error.errors) {
          if (_error.errors.detail && _error.errors.detail === 'Cookie Authentication Failed') {
            _windowProxy.default.changeLocation("".concat(this.appsCoreHyperlinks.signInUrl, "?next=").concat(_windowProxy.default.mungeLocationHash()));

            return undefined;
          }

          return this.handleJsonApiError(_error);
        }

        return this.transitionTo('error');
      },
      didTransition: function didTransition() {
        this.eventbus.trigger('hide-sidebar');
      }
    }
  });

  _exports.default = _default;
});