define("apps-core-ui/templates/components/filter-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P7VkM6KB",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"focus-input\",[],[[\"@inputId\"],[\"filter-input\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui left icon input\"],[12],[2,\"\\n      \"],[8,\"input\",[[24,0,\"filter-input\"]],[[\"@value\",\"@id\",\"@key-up\"],[[34,1],\"filter-input\",[30,[36,0],[[32,0],\"filter\"],null]]],null],[2,\"\\n      \"],[10,\"i\"],[14,0,\"filter list icon\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"ui-popup\",[],[[\"@content\",\"@position\"],[\"Filter\",\"top right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"i\"],[24,0,\"icon big filter list\"],[4,[38,0],[[32,0],\"showFilterInput\"],null],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"filter\",\"filtering\",\"if\"]}",
    "moduleName": "apps-core-ui/templates/components/filter-list.hbs"
  });

  _exports.default = _default;
});