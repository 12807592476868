define("apps-core-ui/components/focus-input", ["exports", "apps-core-ui/templates/components/focus-input"], function (_exports, _focusInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _focusInput.default,
    didRender: function didRender() {
      if (!this.skipFocus) {
        this.set('skipFocus', true);
        document.querySelector("#".concat(this.inputId)).focus();
      }
    }
  });

  _exports.default = _default;
});