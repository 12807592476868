define("apps-core-ui/serializers/hit", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    normalize: function normalize(typeClass, hash) {
      var searchAttrs = {};

      for (var attr in hash.attributes) {
        if (Object.prototype.hasOwnProperty.call(hash.attributes, attr)) {
          searchAttrs[Ember.String.camelize(attr)] = hash.attributes[attr];
        }
      }

      hash.attributes.attrs = searchAttrs;
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});