define("apps-core-ui/adapters/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    namespace: 'api',
    getQuery: function getQuery(snapshot) {
      return Ember.get(snapshot, 'adapterOptions.auth') ? "?auth=".concat(snapshot.adapterOptions.auth) : '';
    },
    host: Ember.computed(function () {
      return this.appsCoreApiDomains.configApi;
    }),
    pathForType: function pathForType(type) {
      return Ember.String.camelize(type);
    },
    buildURL: function buildURL(type, id, snapshot, requestType) {
      var url = this._super(type, id, snapshot, requestType);

      if (url.charAt(url.length - 1) !== '/') {
        url += '/';
      }

      return "".concat(url).concat(this.getQuery(snapshot));
    },
    urlForFindAll: function urlForFindAll() {
      return "".concat(this.appsCoreApiDomains.authApi, "/spoof/companylist");
    }
  });

  _exports.default = _default;
});