define("apps-core-ui/templates/components/spoof-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zk6PICKW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"spoof-warning \",[30,[36,1],[[35,0],\"spoofing-margin\",\"\"],null]]]],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"warning icon spoof-icon\"],[12],[13],[10,\"span\"],[14,1,\"spoof-id\"],[12],[2,\" \"],[1,[34,2]],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"spoof-content\"],[12],[10,\"span\"],[12],[2,\" \"],[1,[34,3]],[2,\" \"],[13],[11,\"button\"],[24,0,\"ui red mini button\"],[4,[38,5],[[32,0],\"invalidateSession\",[35,4]],null],[12],[2,\"Change\"],[13],[2,\" \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"spoofBarVisible\",\"if\",\"companyId\",\"companyName\",\"spoofUrl\",\"action\"]}",
    "moduleName": "apps-core-ui/templates/components/spoof-warning.hbs"
  });

  _exports.default = _default;
});