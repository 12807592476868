define("apps-core-ui/initializers/cors", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function initialize() {
    _jquery.default.ajaxSetup({
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true
    });
  }

  var _default = {
    name: 'cors',
    initialize: initialize
  };
  _exports.default = _default;
});