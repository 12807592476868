define("apps-core-ui/serializers/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, type, payload, id, requestType) {
      payload.id = payload.company_id;
      payload.logoUrl = payload.company_logo_url;
      payload.name = payload.company_name;
      payload.website = payload.clean_website;
      payload.redirect_url = payload.clean_redirect_url;
      payload.apiKey = payload.api_key;
      payload.postalCode = payload.postal_code;
      payload.primaryContactName = payload.primary_contact_name;
      payload.primaryEmail = payload.primary_email;
      payload.primaryFax = payload.primary_fax;
      payload.primaryPhone = payload.primary_phone;
      payload.timeZone = payload.time_zone;
      payload.zipCode = payload.zip_code;
      payload.zipPlus = payload.zip_plus;
      payload.mipsId = payload.mips_id;
      payload.appsPath = payload.apps_path;
      return this._super(store, type, {
        'company': payload
      }, id, requestType);
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, type, payload, id, requestType) {
      var formattedData = [];
      payload.forEach(function (val) {
        formattedData.push({
          label: val.label,
          id: val.value
        });
      });
      return this._super(store, type, {
        'company': formattedData
      }, id, requestType);
    }
  });

  _exports.default = _default;
});