define("apps-core-ui/utils/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getPermissions() {
    return [];
  }

  var _default = {
    getPermissions: getPermissions
  };
  _exports.default = _default;
});