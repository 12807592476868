define("apps-core-ui/templates/components/ui-fab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+665cd47",
    "block": "{\"symbols\":[\"item\",\"idx\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"fab-container \",[34,0]]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"fab-add huge circular ui \",[34,6,[\"color\"]],\" icon button\"]]],[15,\"onmouseover\",[30,[36,1],[[32,0],\"onMouseOver\"],null]],[15,\"onmouseout\",[30,[36,1],[[32,0],\"onMouseOut\"],null]],[15,\"onclick\",[30,[36,1],[[32,0],\"mainAction\",[35,6,[\"event\"]]],null]],[15,\"data-tooltip\",[34,6,[\"tooltip\"]]],[15,\"data-position\",[34,2]],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[\"big icon \",[30,[36,8],[[35,7],\"close\",[35,6,[\"icon\"]]],null]]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,8],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"items-wrapper\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"fab-item fab-items-\",[34,0],\"-\",[32,2],\" circular ui icon button\"]]],[15,\"onclick\",[30,[36,1],[[32,0],\"itemAction\",[32,1,[\"event\"]]],null]],[15,\"data-tooltip\",[32,1,[\"tooltip\"]]],[15,\"data-position\",[34,2]],[12],[2,\"\\n          \"],[10,\"i\"],[15,0,[31,[\"large icon \",[32,1,[\"icon\"]]]]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"open-direction\",\"action\",\"text-direction\",\"actionList\",\"-track-array\",\"each\",\"mainButton\",\"selected\",\"if\",\"useList\"]}",
    "moduleName": "apps-core-ui/templates/components/ui-fab.hbs"
  });

  _exports.default = _default;
});