define("apps-core-ui/templates/components/core-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HfIUbYGE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"footer\"],[14,0,\"footer\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui stackable equal width grid container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"center aligned column\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,1,\"footer-text\"],[12],[2,\"\\n        Powered by\\n        \"],[10,\"img\"],[14,0,\"footer-logo ui middle aligned spaced image\"],[14,\"width\",\"156\"],[14,\"height\",\"170\"],[14,\"alt\",\"IMT Software Services\"],[14,\"src\",\"/assets/images/imt_footer_logo.png\"],[12],[13],[2,\"\\n        IMT Software Services © \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentYear\"]}",
    "moduleName": "apps-core-ui/templates/components/core-footer.hbs"
  });

  _exports.default = _default;
});