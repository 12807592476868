define("apps-core-ui/utils/window-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var windowProxy = {
    changeLocation: function changeLocation(url) {
      window.location = url;
    },
    getLocation: function getLocation() {
      return window.location;
    },
    open: function open(url) {
      window.open(url);
    },
    ga: function ga() {
      var _window;

      (_window = window).ga.apply(_window, arguments);
    },
    mungeLocationHash: function mungeLocationHash() {
      var location = windowProxy.getLocation();
      return "".concat(location).replace('#', '%23');
    },
    system: function system() {
      return window.location.hostname.split('.imtapps.com')[0];
    },
    refresh: function refresh() {
      return window.location.reload();
    },
    removeSession: function removeSession() {
      window.localStorage.removeItem('ember_simple_auth-session');
    }
  };
  var _default = windowProxy;
  _exports.default = _default;
});