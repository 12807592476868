define("apps-core-ui/serializers/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    normalizeResponse: function normalizeResponse(store, type, payload, id, requestType) {
      if (!payload.data) {
        payload.username = payload.user.username;
        payload.first_name = payload.user.first_name;
        payload.last_name = payload.user.last_name;
        payload.company_number = payload.user.company_number || payload.user.company;
        payload.is_staff = payload.user.is_staff;
        payload.email = payload.user.email;
        return this._super(store, type, {
          'data': {
            'attributes': payload,
            'type': 'users',
            'id': 'details'
          }
        }, id, requestType);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});