define("apps-core-ui/mixins/authorized-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.token', function () {
      return {
        'Authorization': "Bearer ".concat(this.session.data.authenticated.token)
      };
    })
  });

  _exports.default = _default;
});