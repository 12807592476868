define("apps-core-ui/templates/components/ui-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J+qi0669",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],[[32,0],\"execute\"],null]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "apps-core-ui/templates/components/ui-calendar.hbs"
  });

  _exports.default = _default;
});