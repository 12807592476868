define("apps-core-ui/instance-initializers/common-error-routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(app) {
    var router = app.factoryFor('router:main').class;
    router.map(function () {
      this.route('error');
      this.route('not-found');
      this.route('forbidden');
      this.route('bad-request');
      this.route('authenticate');
      return undefined;
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});