define("apps-core-ui/adapters/search", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    store: Ember.inject.service(),
    company: Ember.computed(function () {
      return this.store.peekRecord('user', 'details').get('companyNumber');
    }),
    urlForQueryRecord: function urlForQueryRecord() {
      return "".concat(this.appsCoreApiDomains.searchUrl, "/").concat(this.company, "/");
    }
  });

  _exports.default = _default;
});