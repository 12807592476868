define("apps-core-ui/utils/user-is-agent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var userIsAgent = {
    get: function get(store) {
      if (store.peekRecord('user', 'details')) {
        return !!store.peekRecord('user', 'details').get('agency.agency_code');
      }

      return null;
    }
  };
  var _default = userIsAgent;
  _exports.default = _default;
});