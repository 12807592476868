define("apps-core-ui/components/core-steps", ["exports", "ember-data", "apps-core-ui/templates/components/core-steps"], function (_exports, _emberData, _coreSteps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _coreSteps.default,
    current: Ember.computed.reads('steps.firstObject'),
    changesets: Ember.computed(function () {
      return Ember.A();
    }),

    get errors() {
      var _this = this;

      if (this.changesets.length) {
        return _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.all(this.changesets.map(function (v) {
            return v.validate();
          })).then(function () {
            return !_this.changesets.every(function (v) {
              return v.isValid;
            });
          })
        });
      }

      return new Ember.RSVP.Promise(function (resolve) {
        return resolve(false);
      });
    },

    firstStep: Ember.computed('steps.firstObject', 'current.name', function () {
      return this.get('steps.firstObject.name') === this.get('current.name');
    }),
    finalStep: Ember.computed('steps.lastObject', 'current.name', function () {
      return this.get('steps.lastObject.name') === this.get('current.name');
    }),
    nextSteps: Ember.computed('steps.[]', 'current', function () {
      var currentIndex = this.steps.indexOf(this.steps.findBy('name', this.current.name));
      return this.steps.slice(currentIndex + 1);
    }),
    previousSteps: Ember.computed('steps.[]', 'current', function () {
      var currentIndex = this.steps.indexOf(this.steps.findBy('name', this.current.name));
      return this.steps.slice(0, currentIndex);
    }),
    setCurrentStep: function setCurrentStep(newCurrent) {
      this.changesets.forEach(function (e) {
        return e.execute();
      });
      this.set('current', newCurrent);
    },
    actions: {
      save: function save() {
        var _this2 = this;

        this.errors.then(function (errors) {
          if (!errors) {
            _this2.set('canTransition', true);

            _this2.save();
          }
        });
      },
      goToSelectedStep: function goToSelectedStep(step) {
        var _this3 = this;

        this.errors.then(function (errors) {
          if (!errors) {
            _this3.setCurrentStep(step);
          }
        });
      },
      next: function next() {
        var _this4 = this;

        this.errors.then(function (errors) {
          if (!errors) {
            var current = _this4.steps.indexOf(_this4.current);

            _this4.setCurrentStep(_this4.steps[current + 1]);
          }
        });
      },
      previous: function previous() {
        var current = this.steps.indexOf(this.steps.findBy('name', this.current.name));
        this.set('current', this.steps[current - 1]);
      },
      cancel: function cancel() {
        this.cancel();
      }
    }
  });

  _exports.default = _default;
});