define("apps-core-ui/initializers/auth", ["exports", "apps-core-ui/authenticators/imt"], function (_exports, _imt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-simple-auth-imt',
    before: 'ember-simple-auth',
    initialize: function initialize(container) {
      container.register('authenticator:imt', _imt.default);
    }
  };
  _exports.default = _default;
});