define("apps-core-ui/components/filter-list", ["exports", "apps-core-ui/mixins/filterable", "apps-core-ui/templates/components/filter-list"], function (_exports, _filterable, _filterList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_filterable.default, {
    layout: _filterList.default,
    keyManager: Ember.inject.service(),
    willDestroy: function willDestroy() {
      this.filterList('');
    },
    didRender: function didRender() {
      var showFilterMacro = this.keyManager.addMacro({
        callback: Ember.run.bind(this, function () {
          this.showFilterInput();
        }),
        element: document.body,
        keyEvent: 'keydown',
        executionKey: 'f',
        modifierKeys: ['control']
      });
      this.set('showFilterMacro', showFilterMacro);
    },
    willDestroyElement: function willDestroyElement() {
      var showFilterMacro = this.showFilterMacro;
      this.keyManager.removeMacro(showFilterMacro);
    },
    showFilterInput: function showFilterInput() {
      this.set('filtering', true);
    },
    actions: {
      showFilterInput: function showFilterInput() {
        this.showFilterInput();
      },
      filter: function filter(_filter) {
        this.filterList(_filter);
      }
    }
  });

  _exports.default = _default;
});