define("apps-core-ui/components/loading-dropdown", ["exports", "apps-core-ui/templates/components/loading-dropdown"], function (_exports, _loadingDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _loadingDropdown.default,
    tagName: '',
    checkLoaded: function checkLoaded(obj) {
      if (obj) {
        return Ember.get(obj, 'isLoaded') || Ember.get(obj, 'isFulfilled') || Ember.get(obj, 'isSettled');
      }
    },
    loaded: Ember.computed('model', 'model.{content,isUpdating,isLoaded,isSettled,isFulfilled}', function () {
      return this.checkLoaded(this.get('model.content')) || this.checkLoaded(this.model);
    })
  });

  _exports.default = _default;
});