define("apps-core-ui/adapters/relation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    host: Ember.computed(function () {
      return this.appsCoreApiDomains.authApi;
    }),
    namespace: 'api',
    store: Ember.inject.service(),
    company: Ember.computed(function () {
      return this.store.peekRecord('user', 'details').get('companyNumber');
    }),
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this.host, "/").concat(this.company, "/").concat(this.namespace, "/relations/");
    }
  });

  _exports.default = _default;
});