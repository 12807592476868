define("apps-core-ui/components/user-spoof-warning", ["exports", "apps-core-ui/templates/components/user-spoof-warning", "apps-core-ui/utils/window-proxy"], function (_exports, _userSpoofWarning, _windowProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _userSpoofWarning.default,
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    userToSpoof: undefined,
    availableUsers: Ember.computed('companyId', function () {
      return this.store.findAll('user');
    }),
    actions: {
      setSpoofUser: function setSpoofUser(user) {
        this.set('userToSpoof', user);
      },
      spoofUser: function spoofUser() {
        var _this = this;

        var user = this.userToSpoof;
        this.ajax.request("".concat(this.authApi, "/").concat(user.get('companyNumber'), "/api/users/spoof/").concat(user.get('id'))).then(function () {
          _this.flashMessages.positive("Successfully spoofing ".concat(user.get('firstName'), " ").concat(user.get('lastName')));

          _windowProxy.default.removeSession();

          _windowProxy.default.refresh();
        }, function () {
          _this.flashMessages.negative("Error spoofing ".concat(user.get('firstName'), " ").concat(user.get('lastName')));
        });
      },
      endSpoof: function endSpoof() {
        var _this2 = this;

        this.get('userToSpoof', undefined);
        this.ajax.request("".concat(this.authApi, "/").concat(this.companyId, "/api/users/spoof/end")).then(function () {
          _this2.flashMessages.positive('You are no longer spoofing any user');
        }, function () {
          _this2.flashMessages.negative('An error has occurred. Please try again.');
        });
      }
    }
  });

  _exports.default = _default;
});