define("apps-core-ui/components/core-ui-sidebar", ["exports", "semantic-ui-ember/components/ui-sidebar"], function (_exports, _uiSidebar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uiSidebar.default.extend({
    eventbus: Ember.inject.service(),
    didInitSemantic: function didInitSemantic() {
      this._super.apply(this, arguments);

      this.eventbus.on('toggle-sidebar', this, 'toggle');
      this.eventbus.on('hide-sidebar', this, 'hide');
      this.execute('setting', 'transition', 'overlay');
      this.execute('setting', 'mobileTransition', 'overlay');
    },
    toggle: function toggle() {
      this.execute('toggle');
    },
    hide: function hide() {
      this.execute('hide');
    }
  });

  _exports.default = _default;
});