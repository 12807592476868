define("apps-core-ui/components/ui-cloudsearch", ["exports", "apps-core-ui/templates/components/ui-cloudsearch", "jquery"], function (_exports, _uiCloudsearch, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    layout: _uiCloudsearch.default,
    store: Ember.inject.service(),
    search: Ember.inject.service(),
    router: Ember.inject.service(),
    media: Ember.inject.service(),
    advancedSearchRoute: 'search',
    showAdvancedSearch: Ember.computed(function () {
      return true;
    }),
    fields: Ember.computed(function () {
      return ['claim_number', 'policy_number', 'claim_insured', 'insured_name'];
    }),
    sort: Ember.computed(function () {
      return 'claim_report_date desc';
    }),
    operator: Ember.computed(function () {
      return 'OR';
    }),
    size: Ember.computed(function () {
      return this.get('media.isMobile') ? 6 : 10;
    }),
    filterParams: Ember.computed(function () {
      return {};
    }),
    term: Ember.computed(function () {
      return '';
    }),
    user: Ember.computed(function () {
      return this.store.peekRecord('user', 'details');
    }),
    advancedSearch: Ember.computed(function () {
      return this.showAdvancedSearch && this.search.hasAdvancedSearchRoute(this.advancedSearchRoute);
    }),
    templates: Ember.computed(function () {
      var advancedSearch = this.advancedSearch ? "<a class=\"action\" href=\"".concat(this.search.getAdvancedSearchUrl(), "\">Advanced Search</a>") : '';
      return {
        message: function message(msg) {
          if (msg === 'There was an issue querying the server.') {
            return "<div class=\"result\"><div class=\"message\">".concat(msg, "</div></div>");
          }

          return "<div class=\"result\"><div class=\"header\">No Results</div></div>".concat(advancedSearch);
        }
      };
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      (0, _jquery.default)("#".concat(this.elementId)).search({
        minCharacters: 3,
        apiSettings: this.apiSetting,
        class: this.class,
        selectFirstResult: true,
        maxResults: this.size,
        silent: true,
        onSelect: this.onSelect,
        templates: this.templates
      });
    },
    apiSetting: Ember.computed(function () {
      var _this$search,
          _this = this;

      var env = Ember.getOwner(this).factoryFor('appsCoreApiDomains:main').class;
      var company = this.get('user.companyNumber');

      var url = (_this$search = this.search).buildRequestData.apply(_this$search, _toConsumableArray(this.search.getQueryObject(this.fields, this.sort, this.operator, this.size, this.filterParams)));

      return {
        url: "".concat(env.searchUrl, "/").concat(company).concat(url),
        onRequest: function onRequest() {
          return _this.onRequest();
        },
        onResponse: function onResponse(response) {
          return _this.onResponse(response);
        }
      };
    }),
    onRequest: function onRequest() {
      this.set('loading', true);
    },
    onResponse: function onResponse(response) {
      this.set('loading', false);
      return this.search.formatResults(response, this.user, this.showAdvancedSearch, this.advancedSearchRoute);
    },
    onSelect: function onSelect() {
      Ember.run.later(this, function () {
        document.querySelector('#search-bar .prompt').value = '';
      });
    }
  });

  _exports.default = _default;
});