define("apps-core-ui/services/search", ["exports", "apps-core-ui/utils/window-proxy"], function (_exports, _windowProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    hasAdvancedSearchRoute: function hasAdvancedSearchRoute(route) {
      try {
        if (this.router.urlFor(route)) {
          return true;
        }
      } catch (error) {
        return false;
      }
    },
    buildRequestData: function buildRequestData(fields, sort, operator, size, filterParams) {
      var boostParams = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
      var queryString = "/?operator=".concat(operator);
      fields.forEach(function (field) {
        queryString += "&search[".concat(field, "]=", '{query}');
      });

      if (filterParams) {
        Object.keys(filterParams).forEach(function (param) {
          queryString += "&filter[".concat(param, "]=").concat(filterParams[param]);
        });
      }

      if (boostParams) {
        Object.keys(boostParams).forEach(function (param) {
          queryString += "&boost[".concat(param, "]=").concat(boostParams[param]);
        });
      }

      queryString += size ? "&size=".concat(size) : '';
      queryString += sort ? "&sort=".concat(sort) : '';
      return queryString;
    },
    getAdvancedSearchUrl: function getAdvancedSearchUrl() {
      var env = Ember.getOwner(this).resolveRegistration('config:environment');
      var prefix = env.locationType === 'hash' ? '/#' : '';
      return "".concat(prefix, "/search");
    },
    formatResults: function formatResults(response, user, showAdvancedSearch, advancedSearchRoute) {
      var _this = this;

      var newResponse = {
        results: []
      };

      if (!response || !response.data.attributes.found) {
        return;
      }

      this.createAnalytics(user);
      response.included.forEach(function (result) {
        if (result.attributes.result_type === 'policy' && _this.hasRequiredAttributes(result)) {
          return newResponse.results.push(_this.getPolicyResult(result));
        }

        if (result.attributes.claim_status_display) {
          return newResponse.results.push(_this.getClaimResult(result));
        }
      });

      if (showAdvancedSearch && this.hasAdvancedSearchRoute(advancedSearchRoute)) {
        newResponse.action = {
          url: this.getAdvancedSearchUrl(),
          text: 'Advanced Search'
        };
      }

      return newResponse;
    },
    createAnalytics: function createAnalytics(user) {
      if (typeof ga === 'function' && user) {
        _windowProxy.default.ga('send', 'event', {
          eventCategory: 'Claims',
          eventAction: 'Search',
          eventLabel: user ? user.get('companyNumber') : undefined
        });
      }
    },
    getTypeIcon: function getTypeIcon(type) {
      var system = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
      var types = {
        policy: 'assignment',
        claim: 'whatshot'
      };

      var _system = system || _windowProxy.default.system();

      if (_system === 'imaging' || _system === 'imagingtest') {
        return "<span class=\"icon\"><i class=\"ui massive icons\"><i class=\"ui massive ".concat(types[type], " icon\"></i><i class=\"ui corner inverted cloud icon\"></i></i></span>");
      } else if (type === 'policy') {
        return "<span class=\"icon\"><i class=\"ui massive icons\"><i class=\"ui massive ".concat(types[type], " icon\"></i><i class=\"ui corner inverted whatshot icon\"></i></i></span>");
      }

      return "<i class=\"ui massive ".concat(types[type], " icon\"></i>");
    },
    getClaimResult: function getClaimResult(result) {
      var number = result.attributes.claim_number ? result.attributes.claim_number : 'Submitted claim';
      var color = result.attributes.claim_is_open === 'True' ? 'green' : 'red';
      var lossDate = new Date(result.attributes.claim_loss_date);
      var title = "".concat(this.getTypeIcon('claim'), "<div class=\"").concat(color, " text\" style=\"float:right\">").concat(result.attributes.claim_status_display, "</div>").concat(number, " for ").concat(result.attributes.claim_insured);
      var date = "".concat(lossDate.getMonth() + 1, "/").concat(lossDate.getDate(), "/").concat(lossDate.getFullYear());
      var description = "".concat(result.attributes.claim_claim_type_display, " loss on ").concat(date, " for policy ").concat(result.attributes.policy_number);
      var url = this.getWithDefault('getUrl', function () {})(result.attributes) || result.attributes.claim_url;
      var claimUrl = url.replace(/\/details\/?$/, '');
      return {
        title: title,
        description: description,
        url: claimUrl
      };
    },
    getPolicyResult: function getPolicyResult(result) {
      var meta = result.attributes.result_meta || '';
      return {
        title: "".concat(this.getTypeIcon('policy'), "<div style=\"float:right\">").concat(meta, "</div>").concat(result.attributes.result_header),
        description: result.attributes.result_description,
        url: this.getWithDefault('getUrl', function () {})(result.attributes) || result.attributes.url
      };
    },
    hasRequiredAttributes: function hasRequiredAttributes(result) {
      return result.attributes.result_type && result.attributes.result_header && result.attributes.url;
    },
    getQueryObject: function getQueryObject() {
      return Array.prototype.slice.call(arguments);
    }
  });

  _exports.default = _default;
});