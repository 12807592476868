define("apps-core-ui/utils/get-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getLocation;

  function getLocation(router) {
    if (router.get('location.location') && router.get('location.location.href')) {
      return router.get('location.location.href').split('#').slice(-1)[0];
    } else if (router.get('location') && router.get('location.path')) {
      return router.get('location.path');
    }

    return '';
  }
});