define("apps-core-ui/components/ui-fab", ["exports", "jquery", "apps-core-ui/templates/components/ui-fab"], function (_exports, _jquery, _uiFab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiFab.default,
    selected: false,
    'open-direction': 'up',
    'text-direction': 'right center',
    useList: Ember.computed('actionList', function () {
      return this.actionList.length > 1;
    }),
    mainButton: Ember.computed('actionList', function () {
      if (this.actionList.length === 1) {
        return {
          tooltip: this.actionList[0].tooltip,
          event: this.actionList[0].event,
          icon: this.actionList[0].icon || 'add',
          color: this.actionList[0].color || 'blue'
        };
      }

      return {
        tooltip: null,
        event: '',
        icon: 'add',
        color: 'blue'
      };
    }),
    actions: {
      mainAction: function mainAction(event) {
        if (!this.useList) {
          if (this.mainAction) {
            this.mainAction();
          } else {
            event.action(event.args);
          }
        } else {
          (0, _jquery.default)('.fab-add').addClass('hover');
        }
      },
      itemAction: function itemAction(event) {
        event.action(event.args);
      },
      onMouseOver: function onMouseOver() {
        if (this.useList) {
          this.set('selected', true);
        }
      },
      onMouseOut: function onMouseOut() {
        if (this.useList) {
          this.set('selected', false);
        }
      }
    }
  });

  _exports.default = _default;
});