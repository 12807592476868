define("apps-core-ui/utils/token", ["exports", "apps-core-ui/utils/get-location"], function (_exports, _getLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getToken = getToken;
  _exports.getTokenData = getTokenData;

  function getToken(router) {
    var token = false;
    var cookies = document.cookie.split(';');
    cookies.forEach(function (cookie) {
      if (cookie.trim().substring(0, 5) === 'auth=') {
        token = cookie.split('=')[1];
      }
    });
    var location = (0, _getLocation.default)(router) || '';

    if (!token && location.indexOf('auth=') >= 0) {
      token = Ember.A(Ember.A(location.split('?')).get('lastObject').split('=')).removeObject('').get('lastObject');
    }

    return token;
  }

  function getTokenData(token) {
    if (token) {
      var tokenData = JSON.parse(atob(token.split('.')[1]));
      tokenData.token = token;
      return tokenData;
    }
  }
});