define("apps-core-ui/services/event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    create: function create(action, username, company, externalKey, actionMeta) {
      var record = this.store.createRecord('event-notification', {
        action: action,
        username: username,
        company: company,
        external_key: externalKey,
        // eslint-disable-line camelcase
        action_meta: actionMeta // eslint-disable-line camelcase

      });
      return record.save();
    }
  });

  _exports.default = _default;
});