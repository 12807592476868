define("apps-core-ui/components/ui-calendar", ["exports", "apps-core-ui/templates/components/ui-calendar", "semantic-ui-ember/mixins/base"], function (_exports, _uiCalendar, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_base.default, {
    layout: _uiCalendar.default,
    module: 'calendar',
    classNames: ['ui', 'calendar'],
    initSemanticModule: function initSemanticModule() {
      if (this.initialDate) {
        this.execute('set date', this.initialDate);
      }
    }
  });

  _exports.default = _default;
});