define("apps-core-ui/instance-initializers/apps-core-env", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(app) {
    var config = app.factoryFor('config:environment').class;
    var urls = config.appsCoreEnvironments[config.environment].apiDomains;
    app.register('appsCoreApiDomains:main', urls, {
      instantiate: false
    });
    app.inject('adapter', 'appsCoreApiDomains', 'appsCoreApiDomains:main');
    app.inject('component:core-body', 'appsCoreApiDomains', 'appsCoreApiDomains:main');
    var hyperlinks = config.appsCoreEnvironments[config.environment].hyperlinks;
    app.register('appsCoreHyperlinks:main', hyperlinks, {
      instantiate: false
    });
    Ember.A(['component:core-header', 'component:core-body', 'route']).forEach(function (InjectInto) {
      app.inject(InjectInto, 'appsCoreHyperlinks', 'appsCoreHyperlinks:main');
    });
  }

  var _default = {
    name: 'apps-core-env',
    initialize: initialize
  };
  _exports.default = _default;
});