define("apps-core-ui/templates/components/company-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BweLTYxZ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"a\"],[15,6,[31,[[34,0]]]],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"company-logo ui image\"],[15,\"src\",[31,[[34,1]]]],[14,\"alt\",\"logo\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"a\"],[14,6,\"http://www.imtapps.com\"],[12],[2,\"\\n    \"],[8,\"default-logo\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"companyWebsite\",\"companyLogoUrl\",\"if\"]}",
    "moduleName": "apps-core-ui/templates/components/company-logo.hbs"
  });

  _exports.default = _default;
});