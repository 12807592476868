define("apps-core-ui/adapters/event-notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_emberData.default.BuildURLMixin, {
    buildURL: function buildURL() {
      return this.appsCoreApiDomains.eventNotificationUrl;
    },
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL();
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      delete data.eventNotification.id;
      return this.ajax(url, 'POST', {
        data: data.eventNotification
      });
    }
  });

  _exports.default = _default;
});