define("apps-core-ui/utils/localize", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = localize;

  function localize(dateString, format) {
    var date = _moment.default.utc(dateString + (0, _moment.default)(dateString).format('Z'));

    if (format) {
      return date.format(format);
    }

    return "".concat(date.format('YYYY-MM-DDTHH:mm:ss.SSS'), "Z");
  }
});