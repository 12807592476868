define("apps-core-ui/templates/components/ui-app-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l6o+NcbO",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[32,0,[\"insuredUser\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui-dropdown\",[],[[\"@class\",\"@forceSelection\"],[\"right fitted item apps-menu\",false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"huge apps icon\"],[14,1,\"applicationsButton\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n      \"],[8,\"app-menu-item\",[],[[\"@applications\"],[[34,4]]],null],[2,\"\\n\"],[6,[37,2],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"app-menu-item\",[],[[\"@applications\"],[[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[8,\"app-menu-item\",[],[[\"@applications\"],[[34,3]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"button\"],[14,0,\"fluid ui button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"showMoreButtons\"],null]],[14,1,\"moreLinksButton\"],[12],[2,\"More\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"admins\",\"if\",\"partners\",\"systems\",\"expandMenu\",\"unless\"]}",
    "moduleName": "apps-core-ui/templates/components/ui-app-menu.hbs"
  });

  _exports.default = _default;
});