define("apps-core-ui/models/agencies", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    agencyId: _emberData.default.attr('string'),
    agencyName: _emberData.default.attr('string'),
    addressLineOne: _emberData.default.attr('string'),
    addressLineTwo: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    zipPlus: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    name2: _emberData.default.attr('string'),
    agencyCode: _emberData.default.attr('string'),
    longAgencyCode: _emberData.default.attr('string'),
    primaryPhone: _emberData.default.attr('string'),
    grinnellProducerCode: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    address: Ember.computed('addressLineOne', 'addressLineTwo', 'city', 'state', 'country', 'postalCode', 'zipCode', function () {
      //eslint-disable-line
      var zip = this.zipCode ? this.zipCode : this.postalCode;
      var location = "".concat(this.addressLineOne, " ").concat(this.addressLineTwo, ",");
      var address = "".concat(location, " ").concat(this.city, ", ").concat(this.state, " ").concat(zip);
      var formattedAddress = address.replace(/null|undefined/g, '').replace(/ {2,9}/g, ' ').replace(/ ,/g, ',').trim();
      return formattedAddress === ',,' ? '' : formattedAddress;
    })
  });

  _exports.default = _default;
});