define("apps-core-ui/components/core-footer", ["exports", "apps-core-ui/templates/components/core-footer"], function (_exports, _coreFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _coreFooter.default,
    tagName: '',
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    })
  });

  _exports.default = _default;
});