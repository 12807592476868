define("apps-core-ui/models/event-notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    action: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    external_key: _emberData.default.attr('string'),
    // eslint-disable-line camelcase
    company: _emberData.default.attr('number'),
    action_meta: _emberData.default.attr(),
    // eslint-disable-line camelcase
    meta: _emberData.default.attr()
  });

  _exports.default = _default;
});