define("apps-core-ui/templates/components/dropdown-agency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TuOhZcE7",
    "block": "{\"symbols\":[\"execute\",\"mapper\",\"item\"],\"statements\":[[8,\"loading-dropdown\",[],[[\"@model\"],[[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"ui-dropdown\",[],[[\"@id\",\"@class\",\"@fullTextSearch\",\"@forceSelection\",\"@selected\",\"@onChange\"],[[34,4],[34,5],\"exact\",[34,6],[34,7],[34,8]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"default text\"],[12],[2,\"Select Agency\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"item\"],[15,\"data-value\",[31,[[34,9]]]],[12],[2,\"\\n        \"],[1,[34,10]],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,12],[[30,[36,11],[[30,[36,11],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"item\"],[15,\"data-value\",[31,[[30,[36,2],[[32,2],[30,[36,1],[[32,3],[35,0]],null]],null]]]],[12],[2,\"\\n          \"],[10,\"b\"],[12],[1,[32,3,[\"agencyName\"]]],[13],[10,\"br\"],[12],[13],[2,\"\\n          [\"],[1,[32,3,[\"agencyCode\"]]],[2,\"] \"],[1,[32,3,[\"address\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mapValue\",\"get\",\"map-value\",\"agencies\",\"id\",\"class\",\"forceSelection\",\"agency\",\"onChange\",\"noAgencyValue\",\"noAgencyText\",\"-track-array\",\"each\"]}",
    "moduleName": "apps-core-ui/templates/components/dropdown-agency.hbs"
  });

  _exports.default = _default;
});