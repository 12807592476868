define("apps-core-ui/components/sidebar-buttons", ["exports", "apps-core-ui/templates/components/sidebar-buttons"], function (_exports, _sidebarButtons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sidebarButtons.default
  });

  _exports.default = _default;
});