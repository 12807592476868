define("apps-core-ui/templates/components/dropdown-company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YOxbszN5",
    "block": "{\"symbols\":[\"excute\",\"mapper\",\"item\"],\"statements\":[[8,\"loading-dropdown\",[],[[\"@model\"],[[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"ui-dropdown\",[],[[\"@id\",\"@class\",\"@fullTextSearch\",\"@forceSelection\",\"@onChange\"],[[34,2],[34,3],\"exact\",[34,4],[30,[36,7],[[32,0],[30,[36,6],[[35,5]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"default text\"],[12],[2,\"Select Company\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"item\"],[15,\"data-value\",[31,[[30,[36,0],[[32,2],[32,3,[\"id\"]]],null]]]],[12],[2,\"\\n          \"],[1,[32,3,[\"label\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"map-value\",\"companies\",\"id\",\"class\",\"forceSelection\",\"company\",\"mut\",\"action\",\"-track-array\",\"each\"]}",
    "moduleName": "apps-core-ui/templates/components/dropdown-company.hbs"
  });

  _exports.default = _default;
});