define("apps-core-ui/components/spoof-warning", ["exports", "apps-core-ui/templates/components/spoof-warning", "apps-core-ui/utils/window-proxy"], function (_exports, _spoofWarning, _windowProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _spoofWarning.default,
    session: Ember.inject.service(),
    actions: {
      invalidateSession: function invalidateSession(spoofUrl) {
        _windowProxy.default.removeSession();

        _windowProxy.default.changeLocation(spoofUrl);
      }
    }
  });

  _exports.default = _default;
});