define("apps-core-ui/services/user-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    permissionsData: Ember.inject.service(),
    session: Ember.inject.service(),
    getSystemPermissions: function getSystemPermissions(system) {
      return this.get('permissionsData.permissions')[system] || {};
    },
    getPermissions: function getPermissions(system) {
      var currentPermissionIds = [];

      if (this.get('session.isAuthenticated')) {
        currentPermissionIds = this.get('session.data.authenticated.permissions');
      } else {
        return {};
      }

      var permissions = this.getSystemPermissions(system);
      var userPermissions = {};
      currentPermissionIds.forEach(function (id) {
        if ("".concat(id) in permissions) {
          userPermissions[Ember.String.camelize(permissions["".concat(id)])] = true;
        }
      });
      return userPermissions;
    },
    isSpoofing: function isSpoofing() {
      return this.get('session.data.authenticated.is_spoofing');
    }
  });

  _exports.default = _default;
});