define("apps-core-ui/utils/links", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.thirdParties = _exports.partners = _exports.formatButtons = void 0;

  var formatButtons = function formatButtons(buttons) {
    for (var empty = 0; empty < buttons.length % 3; ++empty) {
      buttons.push({});
    }

    return buttons;
  };

  _exports.formatButtons = formatButtons;
  var thirdParties = Ember.computed('company.imt_liability', function () {
    return _emberData.default.PromiseArray.create({
      promise: this.dashboardLinks.getThirdPartyLinks(this.get('company.imt_liability')).then(function (buttons) {
        return buttons;
      })
    });
  });
  _exports.thirdParties = thirdParties;
  var partners = Ember.computed('thirdParties.[]', function () {
    if (this.thirdParties && this.get('thirdParties.content')) {
      return formatButtons(this.dashboardLinks.getPartnerLinks().concat(this.thirdParties.content));
    }

    return formatButtons(this.dashboardLinks.getPartnerLinks());
  });
  _exports.partners = partners;
});