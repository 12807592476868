define("apps-core-ui/components/app-menu-item", ["exports", "apps-core-ui/templates/components/app-menu-item"], function (_exports, _appMenuItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _appMenuItem.default,
    classNames: ['app-grid item']
  });

  _exports.default = _default;
});