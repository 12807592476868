define("apps-core-ui/components/dropdown-agency", ["exports", "apps-core-ui/templates/components/dropdown-agency"], function (_exports, _dropdownAgency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dropdownAgency.default,
    tagName: '',
    noAgencyText: 'No Agency',
    noAgencyValue: '',
    class: Ember.computed(function () {
      return this.classes || 'search selection';
    }),
    store: Ember.inject.service(),
    mapValue: 'agencyId',
    company: Ember.computed(function () {
      return this.store.peekRecord('user', 'details').get('companyNumber');
    }),
    agencies: Ember.computed(function () {
      var query = this.includeInactive ? {
        company: this.company,
        include: 'inactive'
      } : {
        company: this.company
      };
      return this.store.query('agencies', query);
    })
  });

  _exports.default = _default;
});