define("apps-core-ui/templates/components/ui-cloudsearch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q52ZxKju",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"ui fluid \",[30,[36,1],[[35,0],\"loading \"],null],\"search\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui fluid icon input\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,0,\"prompt\"],[14,\"placeholder\",\"Search\"],[14,4,\"text\"],[12],[13],[2,\"\\n    \"],[10,\"i\"],[14,0,\"search icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"results\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"loading\",\"if\"]}",
    "moduleName": "apps-core-ui/templates/components/ui-cloudsearch.hbs"
  });

  _exports.default = _default;
});