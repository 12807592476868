define("apps-core-ui/components/dropdown-company", ["exports", "apps-core-ui/templates/components/dropdown-company"], function (_exports, _dropdownCompany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dropdownCompany.default,
    store: Ember.inject.service(),
    class: Ember.computed(function () {
      return this.classes || 'search selection';
    }),
    companies: Ember.computed(function () {
      return this.store.findAll('company');
    })
  });

  _exports.default = _default;
});