define("apps-core-ui/mixins/filterable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filterList: function filterList(filter) {
      var _this = this;

      var filtered = this.fullList;

      if (filter) {
        var safeFilter = filter.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); //eslint-disable-line no-useless-escape

        var regex = new RegExp(safeFilter, 'i');
        filtered = this.fullList.filter(function (obj) {
          return _this.filterFields.filter(function (field) {
            var model = obj.model || obj;

            if (field.function) {
              return field.function(regex, model, _this.get(field.name)).get('length');
            }

            return regex.test(model.get(field));
          }).length;
        });
      }

      this.set('filteredList', filtered);
    }
  });

  _exports.default = _default;
});