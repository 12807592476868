define("apps-core-ui/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    companyNumber: _emberData.default.attr('number'),
    email: _emberData.default.attr('string'),
    isStaff: _emberData.default.attr('boolean'),
    agency: _emberData.default.attr(),
    relation: _emberData.default.hasMany(),
    isLoggedInUser: Ember.computed(function () {
      return this.id === 'details';
    })
  });

  _exports.default = _default;
});