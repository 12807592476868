define("apps-core-ui/adapters/service", ["exports", "ember-data", "apps-core-ui/mixins/authorized-header"], function (_exports, _emberData, _authorizedHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_authorizedHeader.default, {
    urlForFindAll: function urlForFindAll() {
      return "".concat(this.appsCoreApiDomains.serviceUrl);
    }
  });

  _exports.default = _default;
});