define("apps-core-ui/routes/not-found", ["exports", "apps-core-ui/mixins/not-found"], function (_exports, _notFound) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_notFound.default, {});

  _exports.default = _default;
});