define("apps-core-ui/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    address: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    apiKey: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    geolocation: _emberData.default.attr('string'),
    logoUrl: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    primaryContactName: _emberData.default.attr('string'),
    imt_liability: _emberData.default.attr('boolean'),
    primaryEmail: _emberData.default.attr('string'),
    primaryFax: _emberData.default.attr('string'),
    primaryPhone: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    timeZone: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    redirect_url: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    zipPlus: _emberData.default.attr('string'),
    mipsId: _emberData.default.attr('string'),
    appsPath: _emberData.default.attr('string'),
    label: _emberData.default.attr('string')
  });

  _exports.default = _default;
});