define("ember-key-manager/utils/modifier-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TO_MODIFIER = _exports.TO_KEY = _exports.SHIFT_MODIFIER = _exports.SHIFT_KEY = _exports.META_MODIFIER = _exports.META_KEY = _exports.CTRL_MODIFIER = _exports.CTRL_KEY = _exports.ALT_MODIFIER = _exports.ALT_KEY = void 0;

  var _TO_MODIFIER, _TO_KEY;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ALT_MODIFIER = 'altKey';
  _exports.ALT_MODIFIER = ALT_MODIFIER;
  var CTRL_MODIFIER = 'ctrlKey';
  _exports.CTRL_MODIFIER = CTRL_MODIFIER;
  var META_MODIFIER = 'metaKey';
  _exports.META_MODIFIER = META_MODIFIER;
  var SHIFT_MODIFIER = 'shiftKey';
  _exports.SHIFT_MODIFIER = SHIFT_MODIFIER;
  var ALT_KEY = 'Alt';
  _exports.ALT_KEY = ALT_KEY;
  var CTRL_KEY = 'Control';
  _exports.CTRL_KEY = CTRL_KEY;
  var META_KEY = 'Meta';
  _exports.META_KEY = META_KEY;
  var SHIFT_KEY = 'Shift';
  _exports.SHIFT_KEY = SHIFT_KEY;
  var TO_MODIFIER = (_TO_MODIFIER = {}, _defineProperty(_TO_MODIFIER, ALT_KEY, ALT_MODIFIER), _defineProperty(_TO_MODIFIER, CTRL_KEY, CTRL_MODIFIER), _defineProperty(_TO_MODIFIER, META_KEY, META_MODIFIER), _defineProperty(_TO_MODIFIER, SHIFT_KEY, SHIFT_MODIFIER), _TO_MODIFIER);
  _exports.TO_MODIFIER = TO_MODIFIER;
  var TO_KEY = (_TO_KEY = {}, _defineProperty(_TO_KEY, ALT_MODIFIER, ALT_KEY), _defineProperty(_TO_KEY, CTRL_MODIFIER, CTRL_KEY), _defineProperty(_TO_KEY, META_MODIFIER, META_KEY), _defineProperty(_TO_KEY, SHIFT_MODIFIER, SHIFT_KEY), _TO_KEY);
  _exports.TO_KEY = TO_KEY;
});