define("apps-core-ui/adapters/agencies", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    store: Ember.inject.service(),
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(this.appsCoreApiDomains.configApi, "/api/agency/").concat(id, "/");
    },
    urlForQuery: function urlForQuery() {
      return "".concat(this.appsCoreApiDomains.configApi, "/api/agency/");
    },
    urlForFindAll: function urlForFindAll() {
      (false && !(false) && Ember.deprecate('Usage of this version of apps-core-ui agencies findAll has been deprecated', false, {
        id: 'apps-core-ui.adapters.agencies.urlForFindAll',
        until: '18.2.0'
      }));
      return "".concat(this.appsCoreApiDomains.configApi, "/api/agency/?company=").concat(this.company);
    }
  });

  _exports.default = _default;
});