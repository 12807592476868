define("apps-core-ui/adapters/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    getCompany: function getCompany(snapshot) {
      return Ember.get(snapshot, 'adapterOptions.companyId') || this.company;
    },
    getQuery: function getQuery(snapshot) {
      return Ember.get(snapshot, 'adapterOptions.auth') ? "?auth=".concat(snapshot.adapterOptions.auth) : '';
    },
    host: Ember.computed(function () {
      return this.appsCoreApiDomains.authApi;
    }),
    namespace: 'api',
    store: Ember.inject.service(),
    company: Ember.computed(function () {
      return this.store.peekRecord('user', 'details').get('companyNumber');
    }),
    urlForFindRecord: function urlForFindRecord(id, _, snapshot) {
      if (id === 'details') {
        return "".concat(this.host, "/").concat(this.namespace, "/user/").concat(id, "/");
      }

      return "".concat(this.host, "/").concat(this.getCompany(snapshot), "/").concat(this.namespace, "/users/").concat(id, "/").concat(this.getQuery(snapshot));
    },
    urlForQuery: function urlForQuery() {
      return "".concat(this.host, "/").concat(this.company, "/").concat(this.namespace, "/users/");
    },
    urlForFindAll: function urlForFindAll() {
      return "".concat(this.host, "/").concat(this.company, "/").concat(this.namespace, "/users/");
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (headers['content-type'] === 'application/vnd.api+json' && Ember.get(payload, 'errors.detail')) {
        payload.errors.status = "".concat(status);
        return {
          errors: [payload.errors]
        };
      }

      return payload;
    }
  });

  _exports.default = _default;
});