define("apps-core-ui/components/ui-app-menu", ["exports", "apps-core-ui/templates/components/ui-app-menu", "apps-core-ui/utils/links", "apps-core-ui/utils/token"], function (_exports, _uiAppMenu, _links, _token) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiAppMenu.default,
    router: Ember.inject.service(),
    dashboardLinks: Ember.inject.service(),
    classNames: ['icon', 'item', 'app-menu', 'mobile', 'or', 'lower', 'hidden'],
    hidden: true,
    transition: false,
    showMoreHidden: true,
    showMoreTransition: false,
    insuredUser: Ember.computed(function () {
      var token = (0, _token.getToken)(this.router);
      return token ? (0, _token.getTokenData)(token).insured : false;
    }),
    actions: {
      click: function click() {
        this.set('hidden', false);
        this.toggleProperty('transition');
      },
      showMoreClick: function showMoreClick() {
        this.set('showMoreHidden', false);
        this.toggleProperty('showMoreTransition');
      },
      showMoreButtons: function showMoreButtons() {
        this.set('expandMenu', true);
      },
      linkTo: function linkTo() {
        this.linkTo.apply(this, arguments);
      }
    },
    thirdParties: _links.thirdParties,
    partners: _links.partners
  });

  _exports.default = _default;
});