define("apps-core-ui/components/core-body", ["exports", "apps-core-ui/templates/components/core-body", "apps-core-ui/utils/get-location", "apps-core-ui/utils/token", "apps-core-ui/utils/links"], function (_exports, _coreBody, _getLocation, _token, _links) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _coreBody.default,
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    userPermissions: Ember.inject.service(),
    dashboardLinks: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: '',
    userLinks: undefined,
    actions: {
      expandMenu: function expandMenu() {
        this.set('expandBar', true);
      }
    },
    isIE: Ember.computed('showIEBanner', function () {
      return this.userAgent.get('browser.isIE');
    }),
    user: Ember.computed('router.currentRouteName', function () {
      return this.store.peekRecord('user', 'details');
    }),
    authApi: Ember.computed(function () {
      return this.appsCoreApiDomains.authApi;
    }),
    displayName: Ember.computed('user.{firstName,lastName,username}', function () {
      var userFullName = "".concat(this.getWithDefault('user.firstName', ''), " ").concat(this.getWithDefault('user.lastName', ''));
      return userFullName.length > 1 ? userFullName : this.get('user.username');
    }),
    companyNumber: Ember.computed('user.companyNumber', 'router.currentRouteName', function () {
      if (this.user) {
        return this.get('user.companyNumber');
      }

      var token = (0, _token.getTokenData)((0, _token.getToken)(this.router));

      if (token) {
        return token.company_id;
      }

      return this.getCompanyFromUrl();
    }),
    isSpoofing: Ember.computed('companyNumber', 'user.isStaff', function () {
      return this.companyNumber && this.get('user.isStaff');
    }),
    spoofBarVisible: Ember.computed('spoofBarSelected', function () {
      return this.userPermissions.isSpoofing() || this.spoofBarSelected;
    }),
    company: Ember.computed('companyNumber', function () {
      if (this.companyNumber) {
        return this.store.peekRecord('company', this.companyNumber);
      }

      return undefined;
    }),
    systems: Ember.computed('company', function () {
      if (this.company) {
        return (0, _links.formatButtons)(this.dashboardLinks.getSystemLinks(this.company));
      }

      return '';
    }),
    thirdParties: _links.thirdParties,
    partners: _links.partners,
    admins: Ember.computed(function () {
      return (0, _links.formatButtons)(this.dashboardLinks.getAdminLinks());
    }),
    permissions: Ember.computed(function () {
      return {
        search: true
      };
    }),
    cookiesNotEnabled: Ember.computed(function () {
      if (!('cookie' in document && (document.cookie.length > 0 || (document.cookie = 'test').indexOf.call(document.cookie, 'test') > -1))) {
        var env = Ember.getOwner(this).factoryFor('config:environment').class;
        return env.appsCoreEnvironments[env.environment].hyperlinks.cookieWarningUrl;
      }

      return '';
    }),
    getCompanyFromUrl: function getCompanyFromUrl() {
      var location = (0, _getLocation.default)(this.router) || '';
      var companyNumber = Ember.A(Ember.A(location.split('?')).get('firstObject').split('/')).removeObject('').get('firstObject');
      return parseInt(companyNumber, 10) ? companyNumber : '';
    }
  });

  _exports.default = _default;
});