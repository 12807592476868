define("apps-core-ui/components/default-logo", ["exports", "apps-core-ui/templates/components/default-logo"], function (_exports, _defaultLogo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _defaultLogo.default,
    tagName: ''
  });

  _exports.default = _default;
});