define("apps-core-ui/components/core-modal", ["exports", "apps-core-ui/templates/components/core-modal", "semantic-ui-ember/components/ui-modal"], function (_exports, _coreModal, _uiModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uiModal.default.extend({
    layout: _coreModal.default,
    duration: 200,
    dimmerSettings: Ember.computed(function () {
      return {
        duration: {
          show: 200,
          hide: 200
        }
      };
    }),
    transition: 'fade',
    eventbus: Ember.inject.service()
  });

  _exports.default = _default;
});